<template>
  <div>
    <a-form ref="form" layout="vertical" :model="form" class="formCon">
      <a-row>
        <a-col :span="12">
          <a-form-item label="第一笔回款时间" name="firstPaymentTime">
            <a-input :disabled="true" allow-clear v-model:value.trim="form.firstPaymentTime" placeholder="">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="客户姓名" name="customerName">
            <a-input :disabled="true" allow-clear v-model:value.trim="form.customerName" placeholder="">
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="服务项目" name="serviceItem">
            <a-input :disabled="true" allow-clear v-model:value.trim="form.serviceItem" placeholder="">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="" name="productType">
            <div class="label-style" slot="label">
              <div>
                <span>产品类型</span>
              </div>
              <a-popover placement="top">
                <template #content>
                  <p class="ls-content">标准产品提成会在每次收款的次月结算；非标产品提成会在订单支付完成的次月结算</p>
                </template>
                <question-circle-outlined class="ls-icon" />
              </a-popover>
            </div>
            <a-input :disabled="true" allow-clear v-model:value.trim="form.productType" placeholder="">
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="订单金额" name="orderAmount">
            <a-input :disabled="true" allow-clear v-model:value.trim="form.orderAmount" placeholder="">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="本次付款" name="paymentAmount">
            <a-input :disabled="true" allow-clear v-model:value.trim="form.paymentAmount" placeholder="">
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <!-- <a-row>
        <a-col :span="12">
          <a-form-item label="提成参考（提成*提成系数）">
            <a-input :disabled="true" allow-clear v-model:value.trim="form.name" placeholder="客户姓名">
            </a-input>
          </a-form-item>
        </a-col>
      </a-row> -->
      <!--订单归属人-->
      <a-row v-for="(item, index) in form.attributablePeople" :key="index">
        <a-col :span="8">
          <a-form-item :name="item.collaboratorId + index">
            <div class="label-style" slot="label">
              <div>
                <span v-if="index == 0">订单归属人</span>
                <span v-else>合作归属人{{ index }}</span>
                <DeleteOutlined v-if="index > 0" @click="deleteAscription(index)"
                  style="color: #1677ff; font-size: 16px; margin-left: 6px; cursor: pointer;" />
              </div>
            </div>
            <a-select v-model:value="item.collaboratorId" @change="getSignedNum($event, index)" placeholder="请选择合作归属人"
              allow-clear style="width: 100%">
              <a-select-option v-for="option in list" :key="option.id" :name="option.name"
                :disabled="option.disabled">{{
      option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="5">
          <a-form-item label="" :name="item.signedNum + index">
            <div class="label-style" slot="label">
                <div>
                  <span>本单所在月签单数</span>
                  <a-popover placement="top">
                    <template #content>
                      <p class="ls-content">只计算了服务类型是留学保录的订单</p>
                    </template>
                    <question-circle-outlined class="ls-icon" />
                  </a-popover>
                </div>
              </div>
            <a-input-number :disabled="true" max="100" min="0" addon-after="单"
              v-model:value="item.signedNum"></a-input-number>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item label="提成比例" :name="item.commissionRate + index">
            <a-input-number max="100" min="0" addon-after="%" v-model:value="item.commissionRate"></a-input-number>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item label="提成分配系数" :name="item.commissionDistributionRate + index">
            <a-input-number max="100" min="0" addon-after="%"
              v-model:value="item.commissionDistributionRate"></a-input-number>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-button @click="addAscription" type="primary" ghost style="margin-bottom: 20px;">添加合作归属人</a-button>
        </a-col>
      </a-row>
      <!--订单跟进人-->
      <div style="display: inline-block;" v-for="(item, index) in form.followUpPeople" :key="index">
        <a-row>
          <a-col :span="8">
            <a-form-item :name="item.collaboratorId + index">
              <div class="label-style" slot="label">
                <div>
                  <span v-if="index == 0">订单跟进人</span>
                  <span v-else>合作跟进人{{ index }}</span>
                  <DeleteOutlined v-if="index > 0" @click="deleteCoop(index)"
                    style="color: #1677ff; font-size: 16px; margin-left: 6px; cursor: pointer;" />
                </div>
              </div>
              <a-select v-model:value="item.collaboratorId" @change="getFollowSignedNum($event, index)"
                placeholder="请选择合作跟进人" allow-clear style="width: 100%">
                <a-select-option v-for="option in list" :key="option.id" :name="option.name"
                  :disabled="option.disabled">{{
      option.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="" :name="item.signedNum + index">
              <div class="label-style" slot="label">
                <div>
                  <span>本单所在月签单数</span>
                  <a-popover placement="top">
                    <template #content>
                      <p class="ls-content">只计算了服务类型是留学保录的订单</p>
                    </template>
                    <question-circle-outlined class="ls-icon" />
                  </a-popover>
                </div>
              </div>
              <a-input-number :disabled="true" max="100" min="0" addon-after="单"
                v-model:value="item.signedNum"></a-input-number>
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="提成比例" :name="item.commissionRate + index">
              <a-input-number max="100" min="0" addon-after="%" v-model:value="item.commissionRate"></a-input-number>
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-form-item label="提成分配系数" :name="item.commissionDistributionRate + index">
              <a-input-number max="100" min="0" addon-after="%"
                v-model:value="item.commissionDistributionRate"></a-input-number>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
          </a-col>
          <a-col :span="14">
            <a-form-item label="" :name="item.affectAll + index">
              <a-checkbox v-model:checked="item.affectAll">同时修改该员工签单月所有签单的提成比例</a-checkbox>
            </a-form-item>
          </a-col>
        </a-row>
      </div>
      <a-row>
        <a-col :span="24">
          <a-button @click="addCoop" type="primary" ghost style="margin-bottom: 20px;">添加合作跟进人</a-button>
        </a-col>
      </a-row>
      <!--管理层-->
      <a-row v-for="(item, index) in form.managers" :key="index">
        <a-col :span="8">
          <a-form-item :name="item.collaboratorId + index">
            <div class="label-style" slot="label">
              <div>
                <span>管理层{{ index + 1 }}</span>
                <DeleteOutlined @click="deleteManage(index)"
                  style="color: #1677ff; font-size: 16px; margin-left: 6px; cursor: pointer;" />
              </div>
            </div>
            <a-select v-model:value="item.collaboratorId" placeholder="请选择管理层" allow-clear style="width: 100%">
              <a-select-option v-for="option in list" :key="option.id" :name="option.name"
                :disabled="option.disabled">{{
      option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item label="提成比例" :name="item.commissionRate + index">
            <a-input-number max="100" min="0" addon-after="%" v-model:value="item.commissionRate"></a-input-number>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item label="提成分配系数" :name="item.commissionDistributionRate + index">
            <a-input-number max="100" min="0" addon-after="%"
              v-model:value="item.commissionDistributionRate"></a-input-number>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-button @click="addManage" type="primary" ghost style="margin-bottom: 20px;">添加管理层</a-button>
        </a-col>
      </a-row>
      <a-form-item>
        <a-button type="primary" @click="submit" :loading="submitLoading">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import TablePage from "@/components/TablePage";
import { starRating, productType } from '@/utils/public';
import dayjs from 'dayjs';
export default {
  components: { TablePage },
  props: ['selectedRows'],
  name: "institutionsCustomerDetail",
  data() {
    return {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      form: {
        attributablePeople: [
          {
            id: null,
            collaboratorId: null,
            commissionRate: 0,
            commissionDistributionRate: 100
          }
        ],
        followUpPeople: [
          {
            id: null,
            collaboratorId: null,
            commissionRate: 0,
            commissionDistributionRate: 100,
            affectAll: true
          }
        ],
        managers: []
      },
      starRating: starRating,
      productType: productType,
      openModal: false,
      showModal: false,
      modalTitle: '',
      modalContent: '',
      counting: false,
      countdown: 5,
      countdownInterval: null,
      startDisabled: false,
      isComfirm: false,
      domain: {},

      attributablePersonList: [],
      followUpPeopleIdList: [],
      signingItemList: [],
      orderAmount: [],
      signingItemList: [],
      source: {},
      list: []
    }
  },
  created() {
    this.getEmployee();

  },
  methods: {
    disabledDate(time) {
      return time > Date.now();
    },

    async getEmployee() {
      try {
        const res = await this.$http.get('/institutions/audit/getEmployee');
        this.list = res.list;
        this.getDetail(this.selectedRows[0].id)
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    async getDetail() {
      try {
        const res = await this.$http.get('/institutions/audit/pass/get', {
          id: this.selectedRows[0].id,
        });
        this.form = res.source;
        this.form.firstPaymentTime = this.form.firstPaymentTime ? dayjs(this.form.firstPaymentTime).format('YYYY-MM-DD HH:mm:ss') : dayjs().format('YYYY-MM-DD HH:mm:ss')
        this.form.attributablePeople = this.form.attributablePeople ? this.form.attributablePeople : [];
        this.form.followUpPeople = this.form.followUpPeople ? this.form.followUpPeople : [];
        this.form.managers = this.form.managers ? this.form.managers : [];
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    async getSignedNum(e, index) {
      try {
        const res = await this.$http.get('/institutions/audit/getSignNum', {
          employeeId: e,
          date: this.form.firstPaymentTime
        });
        this.form.attributablePeople[index].signedNum = res.data;
      } catch ({ message }) {
        this.$message.error(message);
      }
    },
    async getFollowSignedNum(e, index) {
      try {
        const res = await this.$http.get('/institutions/audit/getSignNum', {
          employeeId: e,
          date: this.form.firstPaymentTime
        });
        this.form.followUpPeople[index].signedNum = res.data;
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    filterSouOption(input, option) {
      return option.text.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0;
    },

    changeContent(e) {
      this.currContent = this.pushContent.filter(item => item.id == e)[0];
    },

    async submit() {
      try {
        await this.$refs.form.validate();
      } catch (e) { // 未通过表单验证
        return;
      }
      this.submitLoading = true;
      let { attributablePeople, followUpPeople, managers } = this.form
      try {
        await this.$http.post('/institutions/audit/editCommission', { id: this.selectedRows[0].id, attributablePeople: JSON.stringify(attributablePeople), followUpPeople: JSON.stringify(followUpPeople), managers: JSON.stringify(managers) });
        this.$message.success('提交成功');
        this.$refs.form.resetFields();
        this.$emit('close');
      } catch ({ message }) {
        this.$message.error(message);
      }
      this.submitLoading = false;
    },

    async blurChange(e, info) {
      let { submitUrl, key, needId, title, blurMessage } = info
      let value = e.target._value;
      try {
        let res = await this.$http.get(submitUrl, { [key]: value, id: needId ? this.selectedRows.map(({ id }) => id) : null });
        if (res.code == '200') {
          this.openModal = true;
          let message = ''
          if (typeof blurMessage === 'function') {
            message = blurMessage(res);
          }
          this.modalValue = message;
        }
      } catch ({ message }) {
        //this.$message.error(message);
      }
    },

    handleOk() {
      this.openModal = false;
    },

    addAscription() {
      if (this.form.attributablePeople.length < 4 || !this.form.attributablePeople) {
        this.form.attributablePeople.push({
          id: null,
          signedNum: 0,
          collaboratorId: null,
          commissionRate: 0,
          commissionDistributionRate: 100
        })
      } else {
        return
      }
    },

    deleteAscription(index) {
      this.form.attributablePeople.splice(index, 1)
    },

    addCoop() {
      if (this.form.followUpPeople.length < 4 || !this.form.followUpPeople) {
        this.form.followUpPeople.push({
          id: null,
          signedNum: 0,
          collaboratorId: null,
          commissionRate: 0,
          commissionDistributionRate: 100,
          affectAll: true
        })
      } else {
        return
      }
    },

    deleteCoop(index) {
      this.form.followUpPeople.splice(index, 1)
    },

    addManage() {
      if (this.form.managers.length < 3 || !this.form.managers) {
        this.form.managers.push({
          id: null,
          collaboratorId: null,
          commissionRate: 0,
          commissionDistributionRate: 100
        })
      } else {
        return
      }
    },

    deleteManage(index) {
      this.form.managers.splice(index, 1)
    },
  }
}
</script>

<style scoped lang="scss">
.blueCopy {
  color: blue;
  text-decoration: underline;
}

.ic-title {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 20px;
}

.formCon {
  .ant-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    .ant-col {
      width: 48%;
      margin: 0 5px;
      flex: 1;
    }
  }
}

.label-style {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ls-required {
  color: red;
  margin-right: 4px;
}

.ls-icon {
  margin-left: 4px;
  font-size: 14px;
  color: #999999;
}

.ma-col {
  border: 1px solid #d9d9d9;
  padding: 20px 20px 0 20px;
  border-radius: 8px;

  .ms-flex {
    display: flex;
    margin-bottom: 20px;
  }

  .ms-title {
    width: 90px;
    text-align: right;

    flex-shrink: 0;
  }

  .ms-content {
    flex-shrink: 0;
    width: calc(100% - 130px);
  }

  .ms-scroll {
    flex-shrink: 0;
    width: calc(100% - 130px);
    max-height: 200px;
    overflow-y: scroll;
  }

  .ms-img {
    width: 160px;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: none;
  /*可以设置轨道颜色*/
  padding-right: 2px;
}

/*滚动条*/
::-webkit-scrollbar-thumb {
  background: rgba(142, 142, 142, 0.4);
  border-radius: 6px;
}
</style>
